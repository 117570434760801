input:focus, input:hover, button:hover, button:active{
  outline: none;
}
a, a:hover{
  text-decoration:none;
  color: #000;
}
.header-div{
  width:100%;
  position: relative;
  display: flex;
  flex-wrap: nowrap;
  height: 80px;
  background: #0A1931;
  padding: 15px;
  z-index: 9;
}
.header-open-button {
  display: none;
}
.header-close-button {
  display: none;
}
.header-title{
  color: #fff;
  width: 100%;
  text-decoration: none;
  font-size: 25px;
  font-weight: bold;
}
.header-title:hover{
  text-decoration: none;
  color: #fff;
}
.header-contents{
  display: flex;
  flex-wrap: nowrap;
}
.header-menu{
  text-decoration: none;
  color: #fff;
  font-size: 16px;
  margin:10px;
  text-transform: capitalize;
  transition: 0.5s;
  cursor: pointer;
}
.header-menu:hover{
  text-decoration: none;
  color: #FFC947;
  opacity: 0.6;
}
.header-menu-active{
  color:#FFC947;
}
.header-search-mobile{
  display: none;
}
.header-search{
  flex-wrap: nowrap;
  width: inherit;
  background: transparent;
  padding: 15px 15px 5px 5px;
  position: absolute;
  right: 0;
  top: 80px;
  animation-name: openSearch;
  animation-duration: 0.5s;
}
@keyframes openSearch {
  from {top: 0}
  to{top:80px}
}
@keyframes openSearchMobile {
  from {top: -80px}
  to{top:0}
}
.header-input{
  width: 350px;
  padding:7px;
  border: 2px solid #0A1931;
  background: transparent;
  color: #000;
}
.header-button{
  padding: 9px;
  background: #0A1931;
  color: #fff;
  border: none;
}

.contents{
  padding: 15px;
  background: #F5F5F5;
  min-height: 86vh;
}
.content-title{
  font-size: 20px;
  text-decoration: underline;
  text-decoration-color: #FFC947;
  text-underline-offset: 5px;
  font-weight: bold;
}
.content{
  width: 100%;
  border-radius: 5px;
  background: rgba(10, 25, 49, 0.05);
}
.content-table-title{
  font-weight: bold;
  font-size: 15px;
}
.content-table-text{
  font-size: 15px;
  word-wrap: break-word;
}
.content-table-link{
  color:#174bc4;
}
.content-table-link:hover, .content-table-link:focus, .content-table-link:active{
  color: #174bc4;
}
.content-monospace{
  font-family: 'Courier New', monospace;
}
.overflow-custom::-webkit-scrollbar {
  width: 4px;
}
.overflow-custom::-webkit-scrollbar-track {
  background: #fff; 
}
.overflow-custom::-webkit-scrollbar-thumb {
  background: rgba(0,0,0,0.5);
  border-radius:100px;
}
@keyframes openSlider {
  from{right:-200px;}
  to{right:0}
}
@keyframes closeSlider {
  from{right:0;}
  to{right:-200px;}
}
.jumbo{
  width:100%;
  padding: 15px 15px 15px 40px;
  background: #0A1931;
  border-bottom: 4px solid #FFC947;
}
.home-title{
  font-size: 35px;
  font-weight: bold;
  text-decoration: underline;
  text-decoration-color: #FFC947;
  text-underline-offset: 6px;
}
.home-search-div{
  width:90%;
}
.home-search{
  display:inline-block;
  width: 100%;
  border:none;
  border-radius: 5px;
  padding: 15px 15px 15px 85px;
  margin-top: 30px;
}
.home-search-button{
  padding: 15px;
  background: #FFC947;
  color:#0A1931;
  font-weight: bold;
  width: 100px;
  border-radius: 5px 0 0 5px;
  border:none;
  position: relative;
  top: -54px;
  margin-left: -30px;
}
.summary-home{
  background: #fff;
  border-radius: 5px;
  padding: 15px;
  width: 100%;
  margin-bottom: 15px;
}
.summary-title{
  font-size: 17px;
  text-align: right;
  margin-top: -10px;
}
.summary-content{
  font-size: 25px;
  font-weight: bold;
  text-align: left;
}
.text-hash{
  white-space: nowrap; 
  text-overflow: ellipsis;
  overflow: hidden;
  color: #000;
}
.proposer-detail {
  width: 40%;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  float: right;
}
.image-validator {
  height: 19px;
  width: auto;
  border-radius: 5px;
  margin-right: 10px;
}
.tab-div{
  width: 100%;
  background:transparent;
  display: inline-block;
}
.tab-button{
  padding: 15px;
  width: 50%;
  background: white;
  font-weight: bold;
  border-left: 0;
  border-right: 0;
  border-top: 0;
  border-style: solid;
  border-color: #FFC947;
}
.o1 {
  width: 50px;
  height: 50px;
  background: #fff;
  border-radius: 50%;
  border: 10px solid #FFC947;
  margin-left: 0px;
  margin-right: -50px;
  display: inline-block;
  animation: thecodes1 1.8s infinite alternate;
}
.o2 {
  width: 50px;
  height: 50px;
  background: #fff;
  border-radius: 50%;
  border: 10px solid #0A1931;
  margin: 0 auto;
  display: inline-block;
  animation: thecodes2 1s infinite alternate;
}
.o3 {
  width: 50px;
  height: 50px;
  background: #fff;
  border-radius: 50%;
  border: 10px solid #0A1931;
  margin-left:-50px;
  display: inline-block;
  animation: thecodes3 3s infinite alternate;
}
@keyframes thecodes1{
  30%{margin-right:-50px;margin-left:0;}
  100%{margin-right:-10px;margin-left:0;}
}
@keyframes thecodes2{
  30%{margin-left:0;}
  100%{margin-right:0;}
}
@keyframes thecodes3{
  30%{margin-left:-50px;}
  100%{margin-left:-10px;}
}
.copy-icon{
  cursor: pointer;
}
.pie-chart{
  margin-top:auto;
  margin-bottom:auto;
  margin-right: auto;
  margin-left: auto;
}
.uptime-container{
  display: flex;
  flex-wrap: wrap;
}
.uptime-light{
  width: 13px;
  margin: 1.5px;
  text-align: center;
  height: 13px;
  font-size: 30px;
  border-radius:2px;
}
.dropdown-button{
  text-transform: capitalize;
  width: 100%;
  max-width: 100%;
  border-radius: 3px;
  border: 1px solid grey;
  background: white !important;
  color: black;
}
.dropdown-button:focus, .dropdown-button:active,.dropdown-button:hover{
  outline: none !important;
  box-shadow: none !important;
  border: 1px solid grey !important;
  color: black !important;
}
.dropdown-menu{
  box-shadow: none !important;
  border-radius: 3px;
  border: 1px solid grey;
  background: white !important;
  animation: open-dropdown 0.5s;
  max-height: 300px;
  overflow: auto;
  width: 100%;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}
.switch {
  display: inline-block;
  height: 19px;
  position: absolute;
  width: 45px;
  margin-top: 10px;
}
.switch .checkbox {
  display:none;
}
.slider {
  background-color: #ccc;
  bottom: 0;
  cursor: pointer;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  transition: .4s;
}
.slider:before {
  background-color: #fff;
  bottom: 4px;
  content: "";
  height: 11px;
  left: 4px;
  position: absolute;
  transition: .4s;
  width: 11px;
}
.checkbox:checked + .slider {
  background-color: #66bb6a;
}
.checkbox:checked + .slider:before {
  transform: translateX(26px);
}
.slider.round {
  border-radius: 30px;
}
.slider.round:before {
  border-radius: 50%;
}
.button-filter{
  width:150px;
  background:#0A1931;
  border-radius: 3px;
  color: #fff;
  border: 0;
}
.button-filter:focus, .button-filter:active,.button-filter:hover{
  outline: none !important;
  box-shadow: none !important;
  border: 0 !important;
  color: #fff !important;
}
@keyframes open-dropdown {
  0%{
    opacity: 0.7;
    height: 38px;
  }
  100% {
    opacity: 1;
    height: 300px;
  }
}

@media screen and (max-width: 424px) {
  .proposer-title{
    display: none;
  }
  .pie-chart{
    margin-top: 0;
    width: 100px;
    height: auto;
  }
}
@media screen and (max-width: 767px) {
  .header-contents{
    display: none;
    position: fixed;
    width: 200px;
    height:100%;
    right: 0;
    padding:40px 15px 15px 15px;
    background: #0A1931;
    z-index: 10;
    top:0;
  }
  .show-header-mobile{
    animation-name: openSlider;
    animation-duration: 0.5s;
    display: block;
  }
  .header-menu{
    width: 100%;
    font-size: 17px;
    display: block;
    padding: 10px 0 10px 0;
    margin:0;
  }
  .header-search-div{
    display: none;
    background: #F5F5F5;
  }
  .header-open-button{
    display: block;
    text-decoration: none;
    font-size: 30px;
    color: #fff;
    margin:10px;
    text-transform: capitalize;
    transition: 0.5s;
    cursor: pointer;
  }
  .header-close-button{
    display: block;
    position: absolute;
    font-size: 15px;
    color: #fff;
    top:15px;
    right: 15px;
    cursor: pointer;
  }
  .header-search-mobile{
    display: block;
    width: 100%;
    background: #F5F5F5;
    padding: 15px 15px 5px 5px;
    position: relative;
    padding: 15px;
    animation-name: openSearchMobile;
    animation-duration: 0.5s;
  }
  .header-input{
    display: inline-block;
    width: 85%;
  }
  .header-button{
    display: inline-block;
    width:15%;
  }
  .home-search{
    width:100%;
    padding: 15px 15px 15px 30px;
  }
  .home-search-button{
    width:50px;
  }
  .contents {
    margin-top: -20px;
  }
  .pie-chart{
    margin-top: 0;
    width: 100px;
    height: auto;
  }
  .nonmobile{
    display: none;
  }
  .button-filter{
    width: 280px;
    left: 0;
    right: 0;
    position: relative;
  }
  .mobile-center{
    text-align: center;
  }
}